<template>
  <span
    :class="[`cy-avatar ${initialsBackground}`, {
      'cy-avatar--x-small': xs,
      'cy-avatar--small': sm,
      'cy-avatar--large': lg,
      'cy-avatar--x-large': xl,
      'cy-avatar--is-image': profileImageExists,
    }]"
    :style="{ 'background-color': !_.has(item, 'icon') && initialsBackground }"
    @click="$emit('click', $event)">
    <slot>
      <v-icon
        v-if="_.has(item, 'icon')"
        :class="['cy-avatar__icon', `${iconStyle.name}-color`]"
        :color="iconColor || $static.defaultIconColor"
        :disabled="disabled"
        :size="iconSize">
        {{ item.icon || $static.defaultIcon }}
      </v-icon>
      <img
        v-else-if="profileImageExists"
        :src="item.picture_url"
        alt="avatar"
        class="cy-avatar__image">
      <span
        v-else
        class="cy-avatar__initials">
        {{ initials }}
      </span>
    </slot>
  </span>
</template>

<script>
import { ENV_PRESETS, DEFAULT_ICON, DEFAULT_ICON_COLOR, DEFAULT_COLOR_NAME } from '@/components/inputs/icon-picker.vue'
import { imageExists } from '@/utils/helpers'

export const initialsKeys = ['given_name', 'family_name', 'username', 'name']
export const validKeys = ['picture_url', 'icon', 'color', ...initialsKeys]
export const bgColors = ['#008573', '#7232F9', '#61802C', '#003BF9', '#CB494D', '#D800BB', '#B85E00', '#AB52B4']

export default {
  name: 'CyAvatar',
  props: {
    item: {
      type: Object,
      validator: (item) => _.isEmpty(item) || _.$hasAny(item, validKeys),
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '18',
    },
    xs: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    profileImageExists: false,
  }),
  computed: {
    $static: () => ({
      defaultIcon: DEFAULT_ICON,
      defaultIconColor: DEFAULT_ICON_COLOR,
    }),
    fullName () {
      if (this.item.given_name && this.item.family_name) {
        return `${this.item.given_name} ${this.item.family_name}`
      }
      return _.$getFirstPopulatedValue(this.item, validKeys) || ''
    },
    initials () {
      return this.fullName.split(' ').slice(0, 2).map((word) => word.charAt(0)).join('').toUpperCase()
    },
    initialsBackground () {
      const asciiSum = this.fullName.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0)
      return bgColors[asciiSum % bgColors.length]
    },
    iconStyle () {
      return _.find(ENV_PRESETS, { name: this.item.color }) || _.find(ENV_PRESETS, { name: DEFAULT_COLOR_NAME })
    },
    iconColor () {
      return this.iconStyle?.foreground || DEFAULT_ICON_COLOR
    },
  },
  async mounted () {
    await this.checkPhotoExistence()
  },
  methods: {
    imageExists,
    async checkPhotoExistence () {
      this.profileImageExists = await this.imageExists(this.item.picture_url, { external: true })
    },
  },

}
</script>

<style lang="scss" scoped>
$x-small-size: 16px;
$small-size: 24px;
$normal-size: 32px;
$large-size: 88px;
$x-large-size: 150px;

.cy-avatar {
  display: flex;
  place-content: center center;
  align-items: center;
  height: $normal-size;
  aspect-ratio: 1;
  border-radius: 50%;
  color: cy-get-color("white");

  &__image {
    height: 100%;
  }

  &__initials {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
  }

  &__icon {
    position: relative;
    z-index: 0;
    width: $normal-size;
    height: $normal-size;
    padding: $spacer;
    border: 1px solid cy-get-color("white");
    border-radius: 4px;

    &.dev-color {
      background-color: map.get($slate-grey, "light-4");
    }

    &.prod-color {
      background-color: map.get($teal, "light-2");
    }

    &.success-color {
      background-color: map.get($green, "light-1");
    }

    &.error-color {
      background-color: map.get($red, "light-1");
    }

    &.default-color {
      background-color: map.get($brown, "light-3");
    }

    &.staging-color {
      background-color: map.get($orange, "light-2");
    }

    .cy-avatar--x-small & {
      width: $x-small-size;
      height: $x-small-size;
    }

    .cy-avatar--small & {
      width: $small-size;
      height: $small-size;
    }

    .cy-avatar--large & {
      width: $large-size;
      height: $large-size;
    }

    .cy-avatar--x-large & {
      width: $x-large-size;
      height: $x-large-size;
    }

    &::after {
      content: none;
    }
  }

  &--x-small {
    height: $x-small-size;

    .cy-avatar__initials {
      font-size: 9px;
    }
  }

  &--small {
    height: $small-size;

    .cy-avatar__initials {
      font-size: 10px;
    }
  }

  &--large {
    height: $large-size;

    .cy-avatar__initials {
      font-size: 36px;
    }
  }

  &--x-large {
    height: $x-large-size;

    .cy-avatar__initials {
      font-size: 45px;
    }
  }

  &--is-image {
    overflow: hidden;
  }
}
</style>
